'use strict';

export const handleImageContainerClick = (element = '.js-image-container') => {
  const imageElements = document.querySelectorAll(element);
  if (!imageElements || imageElements.length === 0) return;

  imageElements.forEach(image => {
    const imageURL = image.dataset.url;

    if (imageURL) {
      if (imageURL.indexOf('sf-contextual-widget') === -1) {
        image.style.cursor = 'pointer';
        image.addEventListener('click', (e) => {
          e.stopPropagation();
          window.location.href = imageURL;
        });
      } else {
        const imageTile = image.parentElement;

        imageTile.style.cursor = 'pointer';
        imageTile.classList.add(imageURL);
      }
    }
  });
};
