/**
 * Video controls helper function
 *
 * @param  { HTMLElement } parentElement parent elment of the component.
 */
export const videoControlsHelper = (parentElement) => {
  if (!parentElement) return;

  const video = parentElement.querySelector(".clickableVideo");
  const playPauseButton = parentElement.querySelector(".js-playOrPause");
  const muteButton = parentElement.querySelector(".js-muteOrUnmute");

  if (!video || !playPauseButton || !muteButton) return;

  const pause = playPauseButton.querySelector('.video-pause');
  const play = playPauseButton.querySelector('.video-play');
  const mute = muteButton.querySelector('.video-mute');
  const unmute = muteButton.querySelector('.video-unmute');
  const HIDDEN_CLASS = 'd-none';

  const toggleClass = (element, className) => element.classList.toggle(className);

  const toggleVideoPlayPause = () => {
    video.paused ? video.play() : video.pause();
    toggleClass(play, HIDDEN_CLASS);
    toggleClass(pause, HIDDEN_CLASS);
  };

  const toggleVideoMuteUnmute = () => {
    video.muted = !video.muted;
    toggleClass(mute, HIDDEN_CLASS);
    toggleClass(unmute, HIDDEN_CLASS);
  };

  playPauseButton.addEventListener("click", toggleVideoPlayPause);
  muteButton.addEventListener("click", toggleVideoMuteUnmute);
};

