import { queryAll } from "../../../../domUtil";

export const imageChangeOnhover = (containerSelector) => {
  const images = queryAll(containerSelector);

  images.forEach((img) => {
    const originalSrc = img.getAttribute('src');
    const hoverSrc = img.dataset.hover || originalSrc;
    const originalAlt = img.getAttribute('alt');
    const hoverAlt = img.dataset.hoveralt || originalAlt;
    const pictureElement = img.closest('picture');
    const sourceElements = queryAll('source', pictureElement);

    img.addEventListener('mouseover', () => {
      img.src = hoverSrc;
      img.alt = hoverAlt;

      sourceElements.forEach((source) => {
        source.srcset = source.dataset.hover || originalSrc;
      });
    });

    img.addEventListener('mouseout', () => {
      img.src = originalSrc;
      img.alt = originalAlt;

      sourceElements.forEach((source) => {
        source.srcset = originalSrc;
      });
    });
  });
};
