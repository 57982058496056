'use strict';

/*
* Function to adjust height of description component in side by side component
*/
function adjustSideBySideTileHeight() {
  const mixedComponentTiles = document.querySelectorAll('.experience-commerce_assets-sideBySideComponent .mixed-component .side-by-side-tile');
  if (mixedComponentTiles.length && window.matchMedia('(min-width: 768px)').matches) {
      let descriptionContainer;
      mixedComponentTiles.forEach(tile => {
          descriptionContainer = tile.querySelector('.side-by-side-content');
          descriptionContainer.style.height = tile.clientHeight - tile.querySelector('.side-by-side-content-CTA').clientHeight - tile.querySelector('.js-image-container').clientHeight;
      });
  }
}

document.addEventListener('DOMContentLoaded', () => {
    const clickableImageClass = '.js-image-container';
    const clickableImageElement = document.querySelector(clickableImageClass);
    // Load the JS only if the particular elment is present
    if (clickableImageElement) {
      const { handleImageContainerClick } = require('./experience/components/commerce_assets/helper/clickableBannerImagesHelper');
      handleImageContainerClick(clickableImageClass);
    }

    const videoControlsClass = '.clickable-video-container';
    const videoControlsElements = document.querySelectorAll(videoControlsClass);
    // Load the JS only if the particular elment is present
    if (videoControlsElements && videoControlsElements.length) {
      const { videoControlsHelper } = require('./experience/components/commerce_assets/helper/videoControls.js')
      videoControlsElements.forEach(component => videoControlsHelper(component));
    }

    const imageChangeOnhoverClass = '.js-image-banner';
    const imageChangeOnhoverElement = document.querySelector(imageChangeOnhoverClass);
    // Load the JS only if the particular elment is present
    if (imageChangeOnhoverElement) {
      const { imageChangeOnhover } = require('./experience/components/commerce_assets/helper/imageChangeOnhover.js')
      imageChangeOnhover(imageChangeOnhoverClass);
    }
});

$(window).on('load resize', function () {
  adjustSideBySideTileHeight();
});
